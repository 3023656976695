import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';

const ChipDropper = ({ initialText, promptId }) => {
  const [text, setText] = useState(initialText || '');
  const [chips] = useState([
    { id: 1, displayName: 'User', value: '{{user}}' },
    { id: 2, displayName: 'Date', value: '{{date}}' },
    { id: 3, displayName: 'Email', value: '{{email}}' },
  ]);
  const [draggedChip, setDraggedChip] = useState(null);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const [cursorPos, setCursorPos] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragIndicatorPos, setDragIndicatorPos] = useState({ x: 0, y: 0, visible: false });

  // Update text if initialText prop changes
  useEffect(() => {
    if (initialText !== undefined) {
      setText(initialText);
    }
  }, [initialText, promptId]);

  // Start dragging a chip
  const handleDragStart = (e, chip) => {
    setDraggedChip(chip);
    setIsDragging(true);

    // Create a custom drag image to offset the visual representation
    const dragImage = document.createElement('div');
    dragImage.textContent = chip.displayName;
    dragImage.style.backgroundColor = '#7F00FF';
    dragImage.style.color = 'white';
    dragImage.style.padding = '4px 8px';
    dragImage.style.borderRadius = '16px';
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-1000px'; // Position offscreen initially
    document.body.appendChild(dragImage);

    // Set the drag image with an offset (move it away from cursor)
    e.dataTransfer.setDragImage(dragImage, 0, 0);

    // Remove the temporary element after a short delay
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);
  };

  // End drag
  const handleDragEnd = () => {
    setDraggedChip(null);
    setIsDragging(false);
    setDragIndicatorPos({ ...dragIndicatorPos, visible: false });
  };

  // Handle text change
  const handleTextChange = (e) => {
    setText(e.target.value);
    // Save cursor position when text changes
    setCursorPos(e.target.selectionStart);
  };

  // Update cursor position on click or selection
  const handleSelect = (e) => {
    setCursorPos(e.target.selectionStart);
  };

  // Handle drag over textarea
  const handleDragOver = (e) => {
    e.preventDefault();
    if (!draggedChip || !textareaRef.current) return;

    // Get textarea position
    const textarea = textareaRef.current;
    const rect = textarea.getBoundingClientRect();

    // Calculate relative position within textarea
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Update indicator position
    setDragIndicatorPos({ x, y, visible: true });

    // Try to get cursor position at this point by focusing and selecting
    try {
      // This ensures the textarea is focused
      textarea.focus();

      // Use the browser's built-in ability to calculate cursor position
      if (document.caretPositionFromPoint) {
        // Firefox method
        const range = document.caretPositionFromPoint(e.clientX, e.clientY);
        if (range && range.offsetNode) {
          // We have a valid range
          textarea.setSelectionRange(range.offset, range.offset);
          setCursorPos(range.offset);
        }
      } else if (document.caretRangeFromPoint) {
        // Chrome/Safari method
        const range = document.caretRangeFromPoint(e.clientX, e.clientY);
        if (range && range.startContainer) {
          const offset = textarea.selectionStart;
          setCursorPos(offset);
        }
      } else {
        // Fallback method - approximate position by click
        const mouseEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: e.clientX,
          clientY: e.clientY
        });
        textarea.dispatchEvent(mouseEvent);
        setCursorPos(textarea.selectionStart);
      }
    } catch (err) {
      console.error("Error setting cursor position", err);
    }

    // Auto-scroll if near edges
    if (y < 30 && textarea.scrollTop > 0) {
      textarea.scrollTop -= 10;
    } else if (y > rect.height - 30 && textarea.scrollTop < textarea.scrollHeight - rect.height) {
      textarea.scrollTop += 10;
    }

    if (x < 30 && textarea.scrollLeft > 0) {
      textarea.scrollLeft -= 10;
    } else if (x > rect.width - 30 && textarea.scrollLeft < textarea.scrollWidth - rect.width) {
      textarea.scrollLeft += 10;
    }
  };

  // Handle drop event
  const handleDrop = (e) => {
    e.preventDefault();
    if (!draggedChip || !textareaRef.current) return;

    const textarea = textareaRef.current;
    // Focus the textarea to ensure selection is active
    textarea.focus();

    // Get current cursor position from textarea's selection
    const dropPosition = textarea.selectionStart;
    console.log(`Dropping at position: ${dropPosition}`);

    // Create new text with chip value inserted
    const newText =
        text.substring(0, dropPosition) +
        draggedChip.value +
        text.substring(dropPosition);

    setText(newText);
    setDraggedChip(null);
    setDragIndicatorPos({ ...dragIndicatorPos, visible: false });

    // After state updates, set cursor position after the inserted chip
    setTimeout(() => {
      const newCursorPos = dropPosition + draggedChip.value.length;
      textarea.focus();
      textarea.setSelectionRange(newCursorPos, newCursorPos);
      setCursorPos(newCursorPos);
    }, 0);
  };

  // Add style for blinking animation
  useEffect(() => {
    if (!document.getElementById('cursor-indicator-style')) {
      const style = document.createElement('style');
      style.id = 'cursor-indicator-style';
      style.textContent = `
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
      `;
      document.head.appendChild(style);
    }

    return () => {
      const style = document.getElementById('cursor-indicator-style');
      if (style) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  return (
      <Box
          ref={containerRef}
          sx={{ mx: 'auto', p: 3 }}
      >
        <Typography variant="h6" gutterBottom>
          Edit Prompt Content
        </Typography>

        {/* Available Chips */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {chips.map((chip) => (
              <Chip
                  key={chip.id}
                  label={chip.displayName}
                  draggable
                  onDragStart={(e) => handleDragStart(e, chip)}
                  onDragEnd={handleDragEnd}
                  sx={{ cursor: 'grab', backgroundColor: "#7F00FF", color: "white" }}
              />
          ))}
        </Box>

        {/* Textarea Container with Relative Positioning */}
        <Box
            sx={{
              position: 'relative',
              mb: 2,
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
          {/* Plain HTML Textarea */}
          <textarea
              ref={textareaRef}
              value={text}
              onChange={handleTextChange}
              onSelect={handleSelect}
              onClick={handleSelect}
              onKeyUp={handleSelect}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{
                width: '100%',
                minHeight: '300px',
                padding: '12px',
                fontFamily: 'monospace',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                outline: isDragging ? '2px solid #7F00FF' : 'none',
                caretColor: '#7F00FF',
                resize: 'vertical'
              }}
          />

          {/* Custom Cursor Indicator - Positioned absolutely within the relative Box */}
          {isDragging && dragIndicatorPos.visible && (
              <div
                  style={{
                    position: 'absolute',
                    width: '2px',
                    height: '20px',
                    backgroundColor: '#7F00FF',
                    left: `${dragIndicatorPos.x}px`,
                    top: `${dragIndicatorPos.y - 10}px`,
                    pointerEvents: 'none',
                    zIndex: 1000,
                    animation: 'blink 0.8s infinite'
                  }}
              />
          )}
        </Box>

        {/* Preview */}
        <Typography variant="body2" sx={{ color: 'gray', mb: 1 }}>
          Preview:
        </Typography>
        <Box sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, fontFamily: 'monospace', maxHeight: '200px', overflow: 'auto' }}>
          {text.split(/(\{\{[^}]+\}\})/g).map((part, index) =>
              part.match(/^\{\{[^}]+\}\}$/) ? (
                  <Chip key={index} label={part} sx={{ m: 0.5, backgroundColor: "#7F00FF", color: "white" }} />
              ) : (
                  <span key={index}>{part}</span>
              )
          )}
        </Box>
      </Box>
  );
};

export default ChipDropper;
