import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme
import moment from "moment";
import { TextField, Popper, ClickAwayListener } from "@mui/material";

const DateRangePickerComponent = forwardRef(({ onChange }, ref) => {
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const anchorRef = useRef(null);

  const handleSelect = (ranges) => {
    setRange([ranges.selection]);
    if (onChange) {
      onChange({
        startDate: ranges.selection.startDate
            ? moment(ranges.selection.startDate).valueOf()
            : "",
        endDate: ranges.selection.endDate
            ? moment(ranges.selection.endDate).valueOf()
            : "",
      });
    }
  };

  // **Fixed Reset Function**
  useImperativeHandle(ref, () => ({
    reset: () => {
      // Step 1: Set range to null to force unmount
      setRange(null);

      // Step 2: Recreate range object after a small delay
      setTimeout(() => {
        setRange([
          {
            startDate: null,
            endDate: null,
            key: "selection",
          },
        ]);
      }, 0);

      if (onChange) {
        onChange({ startDate: null, endDate: null });
      }
    },
  }));

  return (
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }} ref={anchorRef}>
        <TextField
            label="Start Date"
            variant="outlined"
            size="small"
            value={range && range[0].startDate ? moment(range[0].startDate).format("YYYY-MM-DD") : ""}
            placeholder="Select Start Date"
            onClick={() => setShowPicker(true)}
            InputProps={{ readOnly: true }}
        />
        <TextField
            label="End Date"
            variant="outlined"
            size="small"
            value={range && range[0].endDate ? moment(range[0].endDate).format("YYYY-MM-DD") : ""}
            placeholder="Select End Date"
            onClick={() => setShowPicker(true)}
            InputProps={{ readOnly: true }}
        />

        <Popper open={showPicker} anchorEl={anchorRef.current} placement="bottom-start">
          <ClickAwayListener onClickAway={() => setShowPicker(false)}>
            <div style={{ background: "white", boxShadow: "0px 4px 10px rgba(0,0,0,0.1)", padding: "10px", borderRadius: "8px" }}>
              {range && (
                  <DateRange
                      ranges={range}
                      onChange={handleSelect}
                      moveRangeOnFirstSelection={false}
                      showSelectionPreview={true}
                      editableDateInputs={true}
                  />
              )}
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
  );
});

export default DateRangePickerComponent;
