import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {deleteCompany, getCompany} from "../services/CompanyService";
import Loader from "../componenets/Loader";
import {useMessageContext} from "../contexts/MessageContext";
import AreYouSure from "../componenets/AreYouSure";
import CompanyTitles from "../componenets/company/CompanyTitles";
import CompanyScheduler from "../componenets/company/CompanyScheduler";
import CalenderPage from "./CalenderPage";
import ImageTemplatesTab from "../componenets/ImageTemplatesTab";
import CompanyTabs from "../componenets/company/companyTabs/CompanyTabs";
import CompanyIntegration from "../componenets/company/CompanyIntegration";
import CompanySEO from "../componenets/company/CompanySEO";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import PromptEditor from "../componenets/PromptEditor";

const CompanyPage = () => {

  const params = useParams();

  const [company, setCompany] = useState();

  const [loader, setLoader] = useState(false);

  let navigate = useNavigate()

  const [refresh, setRefresh] = useState(true);

  const {notificationHandler} = useMessageContext();

  const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    let id = params.id;

    getCompany(id)
        .then(res => {
          setCompany(res.data);
    })

  }, [refresh]);


  const deleteMe = () => {
    deleteCompany(params.id)
        .then(() => notificationHandler.success('Company deleted'))
        .then(() => navigate("/app/companies"))
  }

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  return (
      <>
        <DndProvider backend={HTML5Backend}>
        <AreYouSure title={"Delete Company"} open={deleteCompanyDialog} handleClose={() => setDeleteCompanyDialog(false)} onSubmit={deleteMe} />
        <Loader loading={loader}/>
        <Box sx={{marginTop: "24px", display: "flex", alignItems: "center", justifyContent: "left"}}>
        {company && <Typography sx={{
          fontWeight: {md: 700, xs: "600"},
          fontSize: {md: "40px", xs: "28px"},
          lineHeight: {md: "52px", xs: "38px"}
        }} color="primary.main">Company: {company.name}</Typography>}
        </Box>

        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="disabled tabs example">
            <Tab sx={{textTransform: "none"}} label="Model" />
            <Tab sx={{textTransform: "none"}} label="Titles" />
            <Tab sx={{textTransform: "none"}} label="Calendar" />
            <Tab sx={{textTransform: "none"}} label="Integrations" />
            <Tab sx={{textTransform: "none"}} label="Image Templates" />
            <Tab sx={{textTransform: "none"}} label="Scheduler" />
            <Tab sx={{textTransform: "none"}} label="SEO" />
            <Tab sx={{textTransform: "none"}} label="Prompts" />
          </Tabs>
        </Box>


        {company && currentTab === 0 && <CompanyTabs company={company}
                                              setCompany={setCompany}
                                              setLoader={setLoader}
                                              setDeleteCompanyDialog={setDeleteCompanyDialog}
                                              setRefresh={setRefresh}/>}

        {company && currentTab === 1 && <CompanyTitles company={company}/>}

        {/*{company && currentTab === 2 && <CompanyFeatures company={company} setRefresh={setRefresh} setLoader={setLoader}/>}*/}

        {company && currentTab === 3 && <CompanyIntegration company={company} />}

        {company && currentTab === 5 && <CompanyScheduler company={company} />}
        {company && currentTab === 6 && <CompanySEO company={company} />}

        {company && currentTab === 2 && <CalenderPage company={company} />}

        {company && currentTab === 4 && <ImageTemplatesTab company={company}/>}
        {company && currentTab === 7 && <PromptEditor company={company}/>}
        <Button onClick={() => navigate("/app/companies")}>Back</Button>
        </DndProvider>
      </>
  )
};

export default CompanyPage;
