import httpService from "./HttpService";
import {value} from "lodash/seq";
import {createQueryString} from "../utils/createQueryString";

export async function getFeed(params) {
  const queryString = createQueryString(params);

  const token = localStorage.getItem("auth");
  return httpService.get(`feed${queryString}`, null, token);
}

export async function approveFeed(id) {
  const token = localStorage.getItem("auth");
  return httpService.put(`feed/approve/${id}`, null, token);
}

export async function deleteFeed(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`feed/${id}`, null, token);
}
