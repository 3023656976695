import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {createPrompt, getFormats, getPromptsAvailable} from "../services/PromptsService";

const AddPrompt = ({ open, handleClose, company }) => {
  const TAB_CATEGORIES = [
    { display: "Articles", value: "ARTICLES" },
    { display: "Single Post", value: "SINGLE_POST" },
    { display: "Article Titles", value: "ARTICLE_TITLES" },
    { display: "Article Taglines", value: "ARTICLE_TAGLINES" },
    { display: "Banner Titles", value: "BANNER_TITLES" },
    { display: "News", value: "NEWS" },
    { display: "Weekly News", value: "WEEKLY_NEWS" }
  ];

  const PROMPT_TYPES = [
    { display: "Prompt", value: "PROMPT" },
    { display: "Format", value: "FORMAT" },
    { display: "Sub Format", value: "SUB_FORMAT" }
  ];

  // State management
  const [formData, setFormData] = useState({
    name: "",
    category: "ARTICLES",
    type: "PROMPT",
    format: "",
    systemPrompt: ""
  });
  const [formats, setFormats] = useState([]);
  const [systemPrompts, setSystemPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch formats when needed
  useEffect(() => {
    if (formData.type === "SUB_FORMAT") {
      setIsLoading(true);
      getFormats(company?.id, formData.category)
          .then((res) => {
            setFormats(res.data);
            if (res.data.length > 0) {
              setFormData(prev => ({ ...prev, format: res.data[0].id })); // Store the ID not the whole object
            }
          })
          .catch(error => {
            console.error("Error fetching formats:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
    } else if (formData.type === "PROMPT" && company) {
      setIsLoading(true);
      getPromptsAvailable(company.id, formData.category)
          .then((res) => {
            setSystemPrompts(res.data);
            if (res.data.length > 0) {
              // Set system prompt ID and update name to match the selected prompt
              const selectedPrompt = res.data[0];
              setFormData(prev => ({
                ...prev,
                systemPrompt: selectedPrompt.id,
                name: selectedPrompt.name // Set name to match selected prompt name
              }));
            }
          })
          .catch(error => {
            console.error("Error fetching system prompts:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [company, formData.type, formData.category, open]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // For regular fields update normally
    setFormData(prev => ({ ...prev, [name]: value }));

    // If system prompt is changed, find and update the name too
    if (name === "systemPrompt" && company) {
      const selectedPrompt = systemPrompts.find(prompt => prompt.id === value);
      if (selectedPrompt) {
        setFormData(prev => ({ ...prev, [name]: value, name: selectedPrompt.name }));
      }
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    createPrompt({
      name: formData.name,
      companyId: company?.id,
      category: formData.category,
      subCategory: formData.type,
      formatId: formData.format, // We're now storing just the ID
      promptParentId: formData.systemPrompt
    }).then(handleBack);
  };

  const handleBack = () => {
    setFormData({
      name: "",
      category: "ARTICLES",
      type: "PROMPT",
      format: "",
      systemPrompt: ""
    });

    handleClose();
  }

  return (
      <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: handleSubmit,
          }}
      >
        <DialogTitle>Create Prompt</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
            <TextField
                fullWidth
                margin="dense"
                id="name"
                disabled={company && formData.type === "PROMPT"} // Disable name field when company exists and type is PROMPT
                name="name"
                label="Name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
            />

            <FormControl fullWidth margin="dense">
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                  labelId="category-label"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  label="Category"
              >
                {TAB_CATEGORIES.map((cat) => (
                    <MenuItem key={cat.value} value={cat.value}>
                      {cat.display}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel id="type-label">Prompt Type</InputLabel>
              <Select
                  labelId="type-label"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Prompt Type"
              >
                {PROMPT_TYPES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.display}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>

            {formData.type === "SUB_FORMAT" && (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="format-label">Format</InputLabel>
                  <Select
                      labelId="format-label"
                      id="format"
                      name="format"
                      value={formData.format}
                      onChange={handleChange}
                      label="Format"
                      disabled={isLoading || formats.length === 0}
                  >
                    {formats.map((format) => (
                        <MenuItem key={format.id} value={format.id}>
                          {format.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
            )}

            {formData.type === "PROMPT" && company && (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="system-prompt-label">Available Prompt</InputLabel>
                  <Select
                      labelId="system-prompt-label"
                      id="systemPrompt"
                      name="systemPrompt"
                      value={formData.systemPrompt}
                      onChange={handleChange}
                      label="Available Prompt"
                      disabled={isLoading || systemPrompts.length === 0}
                  >
                    {systemPrompts.map((prompt) => (
                        <MenuItem key={prompt.id} value={prompt.id}>
                          {prompt.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleBack}>
            Cancel
          </Button>
          <Button
              variant="contained"
              type="submit"
              disabled={formData.name === "" || (formData.type === "SUB_FORMAT" && !formData.format) ||
                  (formData.type === "PROMPT" && company && formData.systemPrompt === "")}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default AddPrompt;
