export function createQueryString(paramsObj) {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(paramsObj)) {
    if (value !== undefined && value !== null && value !== '') {
      params.append(key, value.toString());
    }
  }

  return params.toString() ? `?${params.toString()}` : '';
}
