import {Box, Drawer, useMediaQuery} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import {ReactComponent as CompanyIcon} from '../../assets/images/companies.svg';
import {ReactComponent as CompanyWhiteIcon} from '../../assets/images/companies_white.svg';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import SideNavItem from "./SideNavItem";
import {NavLink, useLocation} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";

const SideNav = ({changeMiniNavDir, isMenuOpen, changeIsMenuOpen}) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const {logout} = useAuth();
  const location = useLocation();

  const disconnect = () => {
    logout()
  }
  return (<>
    <Drawer
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "-2px 4px 6px 1px #f2f2f2",
            overflowX: "hidden",
            backgroundColor: "#FFFFFF !important",
            width: '15%',
            display: 'flex',
            left:"auto !important",
            justifyContent: 'space-between'
          }
        }}
        anchor='left'
        onClose={changeIsMenuOpen}
        open={isSmallScreen ? isMenuOpen : true}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
    >
      <Box sx={{display: "flex", flexDirection: "column",justifyContent: "space-between"}}>
        <Box sx={{display: "flex", justifyContent: "center", mt: "20px"}}>
          {/*<Typography sx={{*/}
          {/*  marginTop: "28px",*/}
          {/*  fontWeight: {md: 700, xs: "600"},*/}
          {/*  fontSize: {md: "40px", xs: "28px"},*/}
          {/*  lineHeight: {md: "52px", xs: "38px"}*/}
          {/*}} color="primary.main">UpfrontAi</Typography>*/}
          <img src={require('../../assets/images/logo.jpg')} width={"150px"} />
        </Box>
        <Box sx={{margin: "auto"}}>
          <Box sx={{marginTop: "20px", display: isSmallScreen ? 'none' : "flex", justifyContent: "end",
            cursor: "pointer"}} onClick={changeMiniNavDir}>
          </Box>
        </Box>

          <NavLink onClick={changeIsMenuOpen} to={"/app/feed"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem text="feed" active={location.pathname.includes("feed")} icon={ <RssFeedIcon/>} activeIcon={ <RssFeedIcon/>} />
          </NavLink>

        <NavLink onClick={changeIsMenuOpen} to={"/app/companies"} style={{textDecoration: "none", color: "black"}}>
          <SideNavItem text="companies" active={location.pathname.includes("companies")} icon={ <CompanyIcon/>} activeIcon={ <CompanyWhiteIcon/>} />
        </NavLink>

        <NavLink onClick={changeIsMenuOpen} to={"/app/prompts"} style={{textDecoration: "none", color: "black"}}>
          <SideNavItem text="System Prompts" active={location.pathname.includes("prompts")} icon={ <AutoAwesomeIcon/>} activeIcon={ <AutoAwesomeIcon/>} />
        </NavLink>

        <NavLink onClick={changeIsMenuOpen} to={"/app/playground"} style={{textDecoration: "none", color: "black"}}>
          <SideNavItem text="playground" active={location.pathname.includes("playground")} icon={ <PlayArrowIcon/>} activeIcon={ <PlayArrowIcon/>} />
        </NavLink>


          <NavLink onClick={disconnect} to={"/app/login"} style={{textDecoration: "none", color: "black"}}>
            <SideNavItem text="logout" active={location.pathname.includes("logout")} icon={ <LogoutIcon/>} activeIcon={ <LogoutIcon/>} />
          </NavLink>
      </Box>
    </Drawer>
    </>);
};

export default SideNav;
