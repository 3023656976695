import {useEffect, useRef, useState} from "react";
import {
  Box, Checkbox,
  Fab,
  FormControl, FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import FeedElement from "../componenets/FeedElement";
import AddIcon from "@mui/icons-material/Add";
import {getCompanies} from "../services/CompanyService";
import {getFeed} from "../services/FeedService";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedOnDemand from "../componenets/FeedOnDemand";
import Loader from "../componenets/Loader";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangePickerComponent from "../componenets/DateRangePickerComponent";

const Feed = () => {

  const [items, setItems] = useState([])

  const [companies, setCompanies] = useState([]);

  const [company, setCompany] = useState("");

  const [type, setType] = useState("");

  const [refresh, setRefresh] = useState(false);

  const [onDemand, setOnDemand] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isManual, setIsManual] = useState(false);

  const [isSeo, setIsSeo] = useState(false);

  const [sortField, setSortField] = useState({
    field: "CREATED_DATE", direction: true});

  const [sortDate, setSortDate] = useState(
      {startDate: null, endDate: null}
  );

  const dateRangeRef = useRef(null);

  useEffect(() => {
    getCompanies()
        .then(res => setCompanies(res.data))
  }, []);

  const resetDateRange = () => {
    if (dateRangeRef.current) {
      dateRangeRef.current.reset();
    }
  };

  const handleSortField = (fieldValue) => {
    setSortField(prv => ({
      field: fieldValue,
      direction: prv ? !prv.direction : true
    }));
  };


  useEffect(() => {
    setLoading(true)
    getFeed({companyId: company?.id,
      type,
      isManual,
      isSeo,
      sortField: sortField.field,
      fromScheduledDate: sortDate.startDate,
      toScheduledDate: sortDate.endDate,
      direction: sortField.direction ? "DESC" : "ASC"})
        .then(res => setItems(res.data))
        .finally(() => setLoading(false))
  }, [company, type, isManual, refresh, onDemand, isSeo, sortField, sortDate]);

  return (
      <>
        <Loader loading={loading}/>
        <FeedOnDemand open={onDemand} handleClose={() => setOnDemand(false)}/>
      <Typography sx={{
        marginTop: "24px",
        fontWeight: {md: 700, xs: "600"},
        fontSize: {md: "40px", xs: "28px"},
        lineHeight: {md: "52px", xs: "38px"}
      }} color="primary.main">Feed</Typography>
        <Box sx={{mt: "12px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Box>
              <FormControl sx={{width: "300px"}}>
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
              >

                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {companies.map((company, i) => {
                  return <MenuItem value={company}>{company.name}</MenuItem>
                })}
              </Select>
                </FormControl>
            </Box>
            <Box>
              <FormControl sx={{width: "300px", ml: "12px"}}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                >

                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={"NEWS"}>News</MenuItem>
                  <MenuItem value={"WEEKLY_DIGEST"}>Weekly Digest</MenuItem>
                  <MenuItem value={"NEWS_GENERAL"}>General news</MenuItem>
                  <MenuItem value={"NEWS_FINANCE"}>Finance news</MenuItem>
                  <MenuItem value={"SINGLE_POST"}>Single Post</MenuItem>
                  <MenuItem value={"ARTICLE"}>Article</MenuItem>
                  <MenuItem value={"BANNER"}>Branding Banner</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel sx={{mt: "3px", ml: "12px"}}
                  control={
                    <Checkbox checked={isManual} onChange={() => setIsManual(prv => !prv)} id={"isManual"} name="IsManual" />
                  }
                  label="Manual"
              />
              <FormControlLabel sx={{mt: "3px", ml: "12px"}}
                                control={
                                  <Checkbox checked={isSeo} onChange={() => setIsSeo(prv => !prv)} id={"isSeo"} name="isSeo" />
                                }
                                label="Seo"
              />
            </Box>
          </Box>
          <Box sx={{mt: "24px", display: "flex", alignItems: "center"}}>
            <Fab sx={{mr: "8px"}} color="primary" aria-label="refresh" onClick={() => {
              setRefresh(prv => !prv);
              resetDateRange();
            }}>
              <RefreshIcon />
            </Fab>
            <Fab color="primary" aria-label="add" onClick={() => setOnDemand(true)}>
              <AddIcon />
            </Fab>
          </Box>
          </Box>
        <DateRangePickerComponent  ref={dateRangeRef} onChange={(res) => setSortDate(res)}/>
        <Table sx={{marginTop: "25px", "&.MuiTable-root" : {
            borderCollapse: "separate",
            borderSpacing: "0 15px",
          }}}>
        <TableHead>
          <TableRow>
            <TableCell  align={"center"}>
              <Box onClick={() => handleSortField("CREATED_DATE")} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                <Typography>Create Date</Typography>
                <Box style={{cursor: "pointer",
                  display: "flex", justifyContent: "center",
                  alignItems: "center"}}>
                  {sortField.field === "CREATED_DATE" && sortField.direction ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                </Box>
              </Box>
            </TableCell>
            <TableCell align={"center"}>
              Company Name
            </TableCell>
            <TableCell align={"center"}>
              Type
            </TableCell>
            <TableCell align={"center"}>
              Status
            </TableCell>
            <TableCell align={"center"}>
              Is Manual
            </TableCell>
            <TableCell align={"center"}>
              <Box onClick={() => handleSortField("SCHEDULED_DATE")} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                <Typography>Scheduled Date</Typography>
                <Box style={{cursor: "pointer",
                  display: "flex", justifyContent: "center",
                  alignItems: "center"}}>
                  {sortField.field === "SCHEDULED_DATE" && sortField.direction ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                </Box>
              </Box>
            </TableCell>
            <TableCell align={"center"}>
              Posting Date
            </TableCell>
            <TableCell align={"center"}>
              <Box onClick={() => handleSortField("REVIEW_DATE")} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                <Typography>Review Date</Typography>
                <Box style={{cursor: "pointer",
                  display: "flex", justifyContent: "center",
                  alignItems: "center"}}>
                  {sortField.field === "REVIEW_DATE" && sortField.direction ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                </Box>
              </Box>
            </TableCell>
            <TableCell align={"center"}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map(function(item, i){
          return <FeedElement setReload={() => setRefresh(prv => !prv)} item={item} key={i} />;
        })}
        </TableBody>
      </Table>
      </>
  )
};

export default Feed;
