import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Alert,
  Fab, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import PromptTableItem from './PromptTableItem';
import { usePrompts } from "../hooks/usePrompts";
import Loader from "./Loader";
import AddIcon from "@mui/icons-material/Add";
import {getFormats} from "../services/PromptsService";
import AddPrompt from "./AddPrompt";

const PromptsTab = ({ company, onPromptSelect }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { prompts, loading, error, fetchPrompts } = usePrompts();
  const [format, setFormat] = useState("");
  const [formats, setFormats] = useState([]);
  const [addPrompt, setAddPrompt] = useState(false);

  // Map tab indices to category objects with display name and API value
  const tabCategories = [
    { display: "Articles", value: "ARTICLES" },
    { display: "Single Post", value: "SINGLE_POST" },
    { display: "Article Titles", value: "ARTICLE_TITLES" },
    { display: "Article Taglines", value: "ARTICLE_TAGLINES" },
    { display: "Banner Titles", value: "BANNER_TITLES" },
    { display: "News", value: "NEWS" },
    { display: "Weekly News", value: "WEEKLY_NEWS" }
  ];

  const category = tabCategories[currentTab].value;

  const handleClose = () => {
    setAddPrompt(false);
  }

  const handleChange = (event, val) => {
    setCurrentTab(val);
  };

  useEffect(() => {
    getFormats(company?.id, category).then((res) => {
      setFormats(res.data);
    });
  }, []);

  useEffect(() => {
    // Only fetch if we have a company ID
      fetchPrompts(company?.id, category, format.id);
      console.log("Yay1")
  }, [currentTab, company?.id, fetchPrompts, format, addPrompt]);

  return (
      <Box>
        <AddPrompt company={company} open={addPrompt} handleClose={handleClose}/>
        <Fab sx={{position: 'fixed', top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',}} color="primary" aria-label="add" onClick={() => {setAddPrompt(true)}} >
          <AddIcon />
        </Fab>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="prompt categories">
            {tabCategories.map((category, index) => (
                <Tab
                    key={index}
                    sx={{ textTransform: "none" }}
                    label={category.display}
                />
            ))}
          </Tabs>
        </Box>

        {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
        )}

        <Loader loading={loading} />
        <Box sx={{mt: "20px"}}>
          <FormControl sx={{width: "300px"}}>
            <InputLabel id="demo-simple-select-label">Format</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Format"
                value={format}
                onChange={(e) => setFormat(e.target.value)}
            >

              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {formats.map((formatVal, i) => {
                return <MenuItem value={formatVal}>{formatVal?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        <Table sx={{
          marginTop: "25px",
          "&.MuiTable-root": {
            borderCollapse: "separate",
            borderSpacing: "0 15px",
          }
        }}>
          <TableHead>
            <TableRow>
              <TableCell align={"left"}>
                Name
              </TableCell>
              <TableCell align={"center"}>
                Category
              </TableCell>
              <TableCell align={"center"}>
                Sub Category
              </TableCell>
              <TableCell align={"center"}>
                Format Name
              </TableCell>
              <TableCell align={"center"}>
                Create Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prompts && prompts.length > 0 ? (
                prompts.map((prompt, i) => (
                    <PromptTableItem
                        item={prompt}
                        key={i}
                        onSelect={() => onPromptSelect(prompt)}
                    />
                ))
            ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No prompts found for this category
                  </TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
  );
};

export default PromptsTab;
