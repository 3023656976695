import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PromptsTab from './PromptsTab';
import ChipDropper from "./dragable/ChipDropper";
import {deletePrompt, getPrompt, updatePrompt} from "../services/PromptsService";
import {useMessageContext} from "../contexts/MessageContext";

const PromptEditor = ({ company }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const {notificationHandler} = useMessageContext();

  // Handle when a prompt is selected from the list
  const handlePromptSelect = async (prompt) => {
    try {
      setLoading(true);

      // Use the prompt ID passed from the clicked item
      const response = await getPrompt(prompt.id);

      // Update state with the complete prompt data
      setSelectedPrompt(response.data);
      setIsEditing(true);
    } catch (err) {
      console.error('Error loading prompt:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deletePrompt(selectedPrompt.id)
        .then(() => notificationHandler.success('Company deleted'))
        .then(handleBack)
        .catch(() => notificationHandler.error('Failed to save changes'))
        .finally(() => setLoading(false));
  }

  // Handle going back to the prompt list
  const handleBack = () => {
    setIsEditing(false);
    setSelectedPrompt(null);
  };

  // Handle saving changes
  const handleSave = (updatedContent) => {
      setLoading(true);

      // Create updated prompt object
      const updatedPrompt = {
        ...selectedPrompt,
        content: updatedContent
      };

      // Use the updatePrompt service function
      updatePrompt(updatedPrompt)
          .then(() => notificationHandler.success('Company deleted'))
          .then(handleBack)
          .catch(() => notificationHandler.error('Failed to save changes'))
          .finally(() => setLoading(false));
  };

  return (
      <Box sx={{ width: '100%', p: 2 }}>
        {isEditing ? (
            // Editor view
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={handleBack} sx={{ mr: 1 }}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">
                  {selectedPrompt?.name || 'Loading...'}
                </Typography>
              </Box>

              {loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <CircularProgress />
                  </Box>
              ) : (
                  <>
                    {/* The ChipDropper component for editing the prompt */}
                    <ChipDropper
                        initialText={selectedPrompt?.content || ''}
                        promptId={selectedPrompt?.id}
                        onSave={(content) => handleSave(content)}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <Button
                          color={"error"}
                          variant="text"
                          onClick={handleDelete}
                          sx={{ mr: 1 }}
                      >
                        Delete
                      </Button>
                      <Button
                          variant="outlined"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            // Get current content from ChipDropper
                            const content = document.querySelector('textarea').value;
                            handleSave(content);
                          }}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </>
              )}
            </Box>
        ) : (
            // List view
            <PromptsTab
                company={company}
                onPromptSelect={handlePromptSelect}
            />
        )}
      </Box>
  );
};

export default PromptEditor;
