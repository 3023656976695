import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {getNews} from "../services/NewsService";
import {getArticle} from "../services/ArticleService";
import ArticleSummery from "./ArticleSummery";
import {getBrandingBanner} from "../services/BrandingBannerService";
import {useMessageContext} from "../contexts/MessageContext";
import NewsPreview from "./feed/NewsPreview";
import BannerPreview from "./feed/BannerPreview";
import {getIntegration} from "../services/IntegrationService";
import {approveFeed} from "../services/FeedService";

const FeedPreview = ({item, handleClose, open}) => {

  const [data, setData] = useState();

  const {notificationHandler} = useMessageContext();

  const [reload, setReload] = useState(false);

  const [integrations, setIntegrations] = useState();

  useEffect(() => {
    if (item.type.includes("NEWS") || item.type.includes("WEEKLY_DIGEST") || item.type.includes("NEWS_GENERAL") || item.type.includes("NEWS_FINANCE")) {
      getNews(item.entityId)
          .then(res => setData(res.data))
    } else if (item.type.includes("ARTICLE") || item.type.includes("SINGLE_POST")) {
      getArticle(item.entityId)
          .then(res => setData(res.data))
    } else if (item.type.includes("BANNER")) {
      getBrandingBanner(item.entityId)
          .then(res => setData(res.data))
    }

    if (item.companyId) {
      getIntegration(item.companyId)
          .then(res => setIntegrations(res.data))
    }
  }, [reload])

  const handleApprove = () => {
    approveFeed(item.id)
        .then(() => setReload(prv => !prv))
        .then(() => notificationHandler.success('Approved'))
        .then(() => handleClose())
        .catch(() => notificationHandler.error('Failed to approve'))
  }

  return (
      <>
        <Dialog
            sx={{"& .MuiDialog-paper": {minWidth: "70%"}}}
            open={open}
            onClose={handleClose}>
          <DialogTitle>Preview</DialogTitle>
          {data &&
          <DialogContent>
            {(item.type.includes("NEWS") || item.type.includes("WEEKLY_DIGEST") || item.type.includes("NEWS_GENERAL") || item.type.includes("NEWS_FINANCE")) &&
              <NewsPreview integration={integrations} data={data} item={item}/>}
            {(item.type.includes("ARTICLE") || item.type.includes("SINGLE_POST")) && <>
            {data && <ArticleSummery integration={integrations} companyId={item.companyId} setReload={setReload} element={data} isArticle={item.type.includes("ARTICLE")}/>}</>}
            {item.type.includes("BANNER") && <BannerPreview data={data} notificationHandler={notificationHandler} integration={integrations}/>}
          </DialogContent>}
          <DialogActions>
            <Button variant={"text"} onClick={handleClose}>close</Button>
            <Button color={"test"} variant={"outlined"} onClick={handleApprove}>Approve</Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default FeedPreview;
