import React, { useMemo } from 'react';
import { TableCell, TableRow } from "@mui/material";
import moment from "moment/moment";

const PromptTableItem = ({ item, onSelect }) => {
  // Define category mappings
  const categoryMap = useMemo(() => ({
    "ARTICLES": "Articles",
    "SINGLE_POST": "Single Post",
    "ARTICLE_TITLES": "Article Titles",
    "ARTICLE_TAGLINES": "Article Taglines",
    "BANNER_TITLES": "Banner Titles",
    "NEWS": "News",
    "WEEKLY_NEWS": "Weekly News"
  }), []);

  const subCategoryMap = useMemo(() => ({
    "PROMPT": "Prompt",
    "FORMAT": "Format",
    "SUB_FORMAT": "Sub Format"
  }), []);

  return (
      <TableRow
          onClick={() => onSelect(item)}
          sx={{
            "&.MuiTableRow-root": {
              border: "1px solid #E7E9EE",
              height: "100px",
              backgroundColor: "#F9FAFB",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#dec7f6"
              },
              boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)"
            }
          }}
      >
        <TableCell align={"left"}>
          {item.name}
        </TableCell>
        <TableCell align={"center"}>
          {categoryMap[item.category] || "Unknown"}
        </TableCell>
        <TableCell align={"center"}>
          {subCategoryMap[item.subCategory] || "Unknown"}
        </TableCell>
        <TableCell align={"center"}>
          {item.formatName || ""}
        </TableCell>
        <TableCell align="center">
          {moment(item.createDate).format("DD/MM/YY HH:mm:ss")}
        </TableCell>
      </TableRow>
  );
};

export default PromptTableItem;
