import {useCallback, useState} from "react";
import {getPrompts} from "../services/PromptsService";

export const usePrompts = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPrompts = useCallback(async (companyId, category, format) => {
    setLoading(true);
    setError(null);

    try {
      const res = await getPrompts(companyId, category, format);
      setPrompts(res.data);
    } catch (err) {
      setError(err.message || "Failed to fetch prompts");
      console.error("Error fetching prompts:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    prompts,
    loading,
    error,
    fetchPrompts
  };
};
