import httpService from "./HttpService";

export async function getPromptsAvailable(companyId, category) {
  const token = localStorage.getItem("auth");
  const queryParams = {};

  if (companyId) {
    queryParams.companyId = companyId;
  }

  if (category) {
    queryParams.category = category;
  }

  const queryString = Object.keys(queryParams).length > 0
      ? '?' + new URLSearchParams(queryParams).toString()
      : '';

  // Make the API call with the optional query string
  return httpService.get(`prompts/available${queryString}`, null, token);
}

export async function getPrompts(companyId, category, format) {
  const token = localStorage.getItem("auth");

  // Build query params object with only existing values
  const queryParams = {};

  if (companyId) {
    queryParams.companyId = companyId;
  }

  if (category) {
    queryParams.category = category;
  }

  if (format) {
    queryParams.formatId = format;
  }

  // Convert query params object to URL query string if there are any params
  const queryString = Object.keys(queryParams).length > 0
      ? '?' + new URLSearchParams(queryParams).toString()
      : '';

  // Make the API call with the optional query string
  return httpService.get(`prompts${queryString}`, null, token);
}

export async function createPrompt(prompt) {
  const token = localStorage.getItem("auth");
  return httpService.post(`prompts`, prompt, token);
}

export async function deletePrompt(id) {
  const token = localStorage.getItem("auth");
  return httpService.delete(`prompts/${id}`, null, token);
}

export async function getFormats(companyId, category) {
  const token = localStorage.getItem("auth");

  const queryParams = {};

  if (companyId) {
    queryParams.companyId = companyId;
  }

  if (category) {
    queryParams.category = category;
  }

  const queryString = Object.keys(queryParams).length > 0
      ? '?' + new URLSearchParams(queryParams).toString()
      : '';

  return httpService.get(`prompts/formats${queryString}`, null, token);
}

export async function getPrompt(id) {
  const token = localStorage.getItem("auth");
  return httpService.get(`prompts/${id}`, null, token);
}

export async function updatePrompt(prompt) {
  const token = localStorage.getItem("auth");
  return httpService.put(`prompts`, prompt, token);
}
